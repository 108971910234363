import axios from 'axios';

export default () => {
  return axios.create({
    // baseURL: `http://localhost:4000`,
    // baseURL: `https://api-dev.recosele.com`,
    baseURL: process.env.VUE_APP_API_URL,
    // baseURL: `https://api2.recosele.com`,
    // baseURL:
    //   process.env.NODE_ENV == 'production' ? `https://api2.recosele.com` : `http://localhost:4000`,
  });
};
