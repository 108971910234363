import Api from './axios_connection';

export default {
  checkPostcode(params) {
    return Api().get('https://zipcloud.ibsnet.co.jp/api/search', {
      params: {
        // ここにクエリパラメータを指定する
        ...params,
      },
    });
  },
  getProducts(params) {
    return Api().get('/products', {
      params: {
        // ここにクエリパラメータを指定する
        perPage: 100000,
        ...params,
      },
    });
  },
  searchProducts(params) {
    return Api().get('/products/search', { params });
  },
  getProduct(id) {
    return Api().get('/products/' + id);
  },
  checkProducts(params) {
    return Api().post('/products/check-archived', params);
  },
  getInformation(id) {
    return Api().get('/information/' + id);
  },
  getFeatures(params) {
    return Api().get('/features', { params });
  },
  getFeatureTags(id) {
    return Api().get('/features/tag/' + id);
  },
  getInformationByBrand(params) {
    return Api().get('/information/', { params });
  },
  getPickupSpots(params) {
    return Api().get('/pickup-spots', params);
  },
  getPayments(params) {
    return Api().get('/payments', { params });
  },
  getPayment(id) {
    return Api().get('/payments/' + id);
  },
  updatePayment({ payment_id, ...obj }) {
    return Api().patch('/payments/' + payment_id, obj);
  },

  /* Stripeによる支払い */
  payByCompany(pay_data) {
    return Api().post('/company-orders', pay_data);
  },

  /* Stripeによる支払い */
  payByStripe(pay_data) {
    return Api().post('/payments/stripe-create-checkout-session', pay_data);
  },
  /* PayPayによる支払い */

  PayPayCheckout(pay_data) {
    return Api().post('/payments/paypay-create-checkout-session', pay_data);
  },
  /* LINEによるログイン */

  LineApiLogin(obj) {
    return Api().post('/users/login', obj);
  },
  GetOrdersByUser(params) {
    return Api().get('/orders', { params });
  },
  GetOrdersByCorporation(params) {
    return Api().get('/company-orders', { params });
  },
  GetOrdersByDay(params) {
    return Api().get('/orders/by_datetime', { params });
  },
  getSpot(id) {
    return Api().get('/pickup-spots/' + id);
  },

  // brands
  getBrands(params) {
    return Api().get('/brands', { params });
  },

  getBrandById(id) {
    return Api().get('/brands/' + id);
  },

  // アカンウトによるログイン
  AccountLogin(obj) {
    return Api().post('/users/account-signin', obj);
  },

  sendSlackMessageCorp(obj) {
    return Api().post('/messages/sendSlackMessageCorp', obj);
  },

  //  not used

  /* Stripeによるcheckout(クレジットによる支払い) */

  //legacy

  //legacy
  GetGroupProduct(id) {
    return Api().get('/get_group_product/' + id);
  },
  getGroupOrderProduct(obj) {
    return Api().post('/get_group_order_product', obj);
  },
  GetAddress(obj) {
    console.log(obj);
    return Api().post('/get_address', obj);
  },
  PostAddress(obj) {
    return Api().post('/post_address', obj);
  },
  ChangePaymentAddress(obj) {
    return Api().post('/change_payment_address', obj);
  },

  GetDormGroupOrder(id) {
    // console.log("API here:"+id)
    return Api().get('/get_dorm_group/' + id);
  },
  UpdateOrder({ id, ...obj }) {
    return Api().patch('/orders/' + id, obj);
  },

  /* 支払い後にpayment状況を表示*/

  GetPayment(id) {
    return Api().get('/get_payment/' + id);
  },
  GetDorm(id) {
    return Api().get('/get_dorm/' + id);
  },
  GetBuildingGroupOrders(address) {
    return Api().get('/get_building_orders/' + address);
  },
  GetBuildingGroupOrder(id, address) {
    return Api().get('/get_building_order/' + id + '/' + address);
  },
  GetOfficialGroupOrders() {
    return Api().get('/get_official_orders');
  },
  GetPayerInfo(id) {
    return Api().get('/get_payer_info/' + id);
  },

  // user
  GetUser({ id }) {
    return Api().get('/users/' + id);
  },
  UpdateUser({ id, ...obj }) {
    return Api().patch('/users/' + id, obj);
  },

  // message
  GetMessages(params) {
    return Api().get('/messages', { params });
  },

  // coupon
  GetCouponByCode(params) {
    return Api().get('/coupons', { params });
  },
  AddCouponToUser(obj) {
    return Api().post('/users/add_coupon', obj);
  },

  // point-histories
  GetPointHistory(params) {
    return Api().get('/point-histories', { params });
  },

  // Reviews
  getReviews(params) {
    return Api().get('/reviews', { params });
  },
  sendReview(obj) {
    return Api().post('/reviews', obj);
  },
};
